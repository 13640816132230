import React from 'react';
import classNames from 'classnames';
import omit from 'lodash/omit';
import Link from '../../SEO/Link';
import { generateSearchPath } from '../../../utils/urlHelpers/listings';
import { Collapsible, CollapsibleContent, CollapsibleHeader } from '../../Collapsible';
import { ToolSetOptions, ToolSetOptionsItem } from '../../ToolSet';

const onClickStateLink = (e) => {
  e.preventDefault();
};

const FilterState = ({ state, selectedState, country, states, position, params, loading, onClickState, searchPage }) => {
  return (
    <div className={classNames('search-filter location', { loading })}>
      <CollapsibleContent initialState="open">
        <CollapsibleHeader>
          {country.map((c) => (
            <div key={c.value}>
              State
              {/*
              <span className="count">({formatNumber(c.count, 'en-US')})</span>
          */}
            </div>
          ))}
        </CollapsibleHeader>
        <Collapsible type="internal">
          <ToolSetOptions>
            <ToolSetOptionsItem
              key="state-all"
              id={`state-all-${position}`}
              name={`state-${position}`}
              value=""
              selected={!selectedState}
              onClick={onClickState}
            >
              <Link
                className="state-link"
                href={generateSearchPath(omit('state', params), omit(params, ['state', 'city', 'zip']), true, searchPage)}
                onClick={onClickStateLink}
              >
                All States
              </Link>
            </ToolSetOptionsItem>
            {states
              .filter((val) => {
                return val.count || state === val.value;
              })
              .map((val) => (
                <ToolSetOptionsItem
                  key={`state-${val.name}`}
                  id={`state-${val.name}-${position}`}
                  name={`state-${position}`}
                  value={val.value}
                  selected={selectedState === val.value}
                  onClick={onClickState}
                >
                  <Link
                    className="state-link"
                    href={generateSearchPath({ state: val.value }, omit(params, ['city', 'zip']), true, searchPage)}
                    onClick={onClickStateLink}
                  >
                    {val.name}
                  </Link>
                </ToolSetOptionsItem>
              ))}
          </ToolSetOptions>
        </Collapsible>
      </CollapsibleContent>
    </div>
  );
};

export default FilterState;
