import React from 'react';
import { useSelector } from 'react-redux';
import './styles.css';

const SearchUserLocation = ({onUserLocationClick}) => {

  const userRadiusMiles = useSelector(state => state.app.userRadiusMiles);
  const userZipCode = useSelector(state => state.app.userZipCode);

  const onClick = () => {
    onUserLocationClick(userZipCode, userRadiusMiles);
  };

  return (userZipCode !== '' &&
    <div className="search-user-location" data-e2e="search-by-user-location" onClick={onClick}>
      Use My Location
    </div>
  );
};

export default SearchUserLocation;
