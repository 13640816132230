import React, { PureComponent } from 'react';
import filter from 'lodash/filter';
import get from 'lodash/get';
import head from 'lodash/head';
import includes from 'lodash/includes';
import classnames from 'classnames';
import { hyphenateUrlComponents, generateSearchPath } from '../../../utils/urlHelpers/listings';
import Link from '../../SEO/Link';
import { CollapsibleContent, CollapsibleHeader, Collapsible } from '../../Collapsible';
import { FilterTypeAhead } from '..';
import { ToolSetOptions, ToolSetOptionsItem } from '../../ToolSet';
import { DEFAULT_REFINE_SEARCH } from '../../../constants/boats';
import { ENGINES_ID, DEFAULT_ENGINE_REFINE_SEARCH } from '../../../constants/Engines';
import './styles.css';

class FilterCity extends PureComponent {
  toggleCity(city, params) {
    const cityIsSelected = includes(params.city, city);
    if (cityIsSelected) {
      return filter(params.city, cityName => cityName !== city);
    }
    return [...params.city, city];
  }
  handleDataChange(type, value, slugCity, selected) {

    if (this.props.tracking) {
      if (selected) {
        this.props.tracking.facetRemoved(`city ${slugCity} removed`);
      } else {
        this.props.tracking.facetAdded({'city': slugCity});
      }
    }

    this.props.handleMultiDataChange({
      zip: DEFAULT_REFINE_SEARCH.zip,
      radius: this.props.searchPage === ENGINES_ID ? DEFAULT_ENGINE_REFINE_SEARCH.radius : DEFAULT_REFINE_SEARCH.radius,
      [type]: value
    });
  }

  renderCities = (items, selectionCallback) => {
    const {position, selectedCities = [], params, searchPage} = this.props;
    return !!items.length && <ToolSetOptions>
      {items
        .map(city => {
          const slugCity = hyphenateUrlComponents(city.value);
          const citySelected = includes(selectedCities, slugCity);
          return <ToolSetOptionsItem
            type={(position === 'mobile' ? 'radio' : 'checkbox')}
            key={`City-${slugCity}-all`}
            id={`City-${slugCity}-all`}
            name={`City-${slugCity}-${position}`}
            value={slugCity}
            selected={citySelected}
            onClick={(value) => {
              //handle data change
              this.handleDataChange('city', this.toggleCity(slugCity, params), slugCity, citySelected);
              selectionCallback(value);
            }}>
            <Link className="city-all-link"
              href={generateSearchPath({city: this.toggleCity(slugCity, params)}, params, true, searchPage)}
              onClick={this.onClickCityLink}>{city.value}</Link>
          </ToolSetOptionsItem>;
        }
        )}
    </ToolSetOptions>;
  }

  onClickCityLink = (e) => {
    e.preventDefault();
  }

  render() {
    const { selectedState, stateCity = [], position, params, searchPage } = this.props;
    if (!selectedState) {
      return null;
    }
    const cities = head(stateCity.filter(({value}) => value === selectedState.value).map(({ city }) => city));
    if (!cities) {
      return null;
    }
    const showAllCities = get(params, 'modal', []).includes('city');
    return <div className={classnames('city-filter', { loading: this.props.loading })}>
      <CollapsibleContent initialState="open" key={`CityCollapsible-${selectedState.value}-${position}`} id={`CityCollapsible-${selectedState.value}-${position}`}>
        <CollapsibleHeader>
          Cities in {selectedState.name}
        </CollapsibleHeader>
        <Collapsible>
          <div className="search-filter cities">
            <FilterTypeAhead
              items={cities}
              selectedItems={[]}
              id={`cities-ahead-${selectedState.value}-${position}`}
              name="Cities"
              placeholder={`Search Cities in ${selectedState.value}`}
              max={20}
              url={generateSearchPath(params, null, null, searchPage) + 'modal-city/'}
              showAll={showAllCities}
              render={this.renderCities} />
          </div>
        </Collapsible>
      </CollapsibleContent>
    </div>;
  }
}

export default FilterCity;
