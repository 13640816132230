import React, { PureComponent } from 'react';
import get from 'lodash/get';
import head from 'lodash/head';
import omit from 'lodash/omit';
import classnames from 'classnames';
import { hyphenateUrlComponents, generateSearchPath } from '../../../utils/urlHelpers/listings';
import Link from '../../SEO/Link';
import { CollapsibleContent, CollapsibleHeader, Collapsible } from '../../Collapsible';
import { FilterTypeAhead } from '..';
import { ToolSetOptions, ToolSetOptionsItem } from '../../ToolSet';
import './styles.css';

class FilterZip extends PureComponent {
  state = {
    zip: ''
  }

  handleDataChange(type, value, slugZip) {
    if (this.props.tracking) {
      this.props.tracking.facetAdded({'zip': slugZip});
    }

    this.props.handleMultiDataChange({
      [type]: value
    });
  }

  updateMultiInput() {
    if (this.props.zip !== undefined) {
      this.setState({ zip: this.props.zip });
    }
  }

  componentDidMount() {
    this.updateMultiInput();
  }

  componentDidUpdate() {
    this.updateMultiInput();
  }

  render() {
    const { selectedCities = [], cityZip = [], position, params, renderAllZips = true, searchPage } = this.props;
    if (selectedCities.length !== 1 || cityZip.length === 0) {
      return null;
    }
    const selectedCity = selectedCities[0];
    const matchCityZip = cityZip.filter(({value}) => value.toLowerCase().replace(/ /g, '-') === selectedCity);
    const cityName = get(matchCityZip[0], 'value', '');
    const zipcodes = head(matchCityZip.map(({ zip }) => zip));
    const showAllZipcodes = get(params, 'modal', []).includes('zip');
    const selectedZipCode = get(params, 'zip', '');
    return <div className={classnames('zip-filter', { loading: this.props.loading })}>
      <CollapsibleContent initialState="open" key={`ZipCollapsible-${selectedCity}-${position}`} id={`ZipCollapsible-${selectedCity}-${position}`}>
        <CollapsibleHeader>
          Zip Codes in {cityName}
        </CollapsibleHeader>
        <Collapsible>
          <div className="search-filter zipcodes">
            <FilterTypeAhead
              items={zipcodes}
              id={`zipcodes-ahead-${selectedCity}-${position}`}
              name="Zip Codes"
              placeholder={`Search Zip Codes in ${cityName}`}
              max={5}
              url={generateSearchPath(params, null, false, searchPage) + 'modal-zip/'}
              showAll={showAllZipcodes}
              render={(items, selectionCallback, renderAllOption = renderAllZips) => {
                const { position, params } = this.props;
                if (renderAllOption) {
                  return !!items.length && <ToolSetOptions>
                    <ToolSetOptionsItem key="zip-all" id={`zip-all-${position}`} name={`zip-${position}`} value="" selected={selectedZipCode === ''} onClick={(value) => this.handleDataChange('zip', value)}>
                      <Link className="zip-all-link" href={generateSearchPath(omit('zip', params), params, true, searchPage)} onClick={(e) => e.preventDefault()}>All Zip Codes</Link>
                    </ToolSetOptionsItem>
                    {  items
                      .filter(zip => zip.value && zip.value.toString().toLowerCase() !== 'other')
                      .map(zip => {
                        const slugZip = hyphenateUrlComponents(zip.value);
                        return <ToolSetOptionsItem
                          key={`Zip-${slugZip}-all`}
                          id={`Zip-${slugZip}-all`}
                          name={`Zip-${slugZip}-${position}`}
                          value={slugZip}
                          selected={selectedZipCode === zip.value}
                          onClick={(value) => {
                            //handle data change
                            this.handleDataChange('zip', zip.value, slugZip);
                            selectionCallback(value);
                          }}>
                          <Link className="zip-all-link" href={generateSearchPath({ zip: slugZip }, params, true, searchPage)} onClick={(e) => e.preventDefault()}>{zip.value}</Link>
                        </ToolSetOptionsItem>;
                      }
                      )}
                  </ToolSetOptions>;
                }

                return !!items.length && <ToolSetOptions>
                  {  items
                    .filter(zip => zip.value && zip.value.toString().toLowerCase() !== 'other')
                    .map(zip => {
                      const slugZip = hyphenateUrlComponents(zip.value);
                      return <ToolSetOptionsItem
                        key={`Zip-${slugZip}-all`}
                        id={`Zip-${slugZip}-all`}
                        name={`Zip-${slugZip}-${position}`}
                        value={slugZip}
                        selected={this.state.zip === zip.value}
                        onClick={(value) => {
                          //handle data change
                          this.handleDataChange('zip', zip.value, slugZip);
                          selectionCallback(value);
                        }}>
                        <Link className="zip-all-link" href={generateSearchPath({ zip: slugZip }, params, true, searchPage)} onClick={(e) => e.preventDefault()}>{zip.value}</Link>
                      </ToolSetOptionsItem>;
                    }
                    )}
                </ToolSetOptions>;

              }} />
          </div>
        </Collapsible>
      </CollapsibleContent>
    </div>;
  }
}

export default FilterZip;
