import React, { Fragment, PureComponent } from 'react';
import classnames from 'classnames';
import { Switcher } from '@dmm/lib-react-ui-components';

import FilterCity from '../FilterCity';
import FilterZip from '../FilterZip';
import SearchUserLocation from '../SearchUserLocation';
import { DEFAULT_REFINE_SEARCH } from '../../../constants/boats';
import { ENGINES_ID, DEFAULT_ENGINE_REFINE_SEARCH } from '../../../constants/Engines';
import FilterState from '../FilterState';

const switcherOptions = [
  {
    label: 'Zip',
    name: 'zip',
    value: 0
  },
  {
    label: 'City / State',
    name: 'city_or_state',
    value: 1
  }
];

class FilterLocation extends PureComponent {
  state = {
    locationOptions: [
      { value: 'exact', label: 'Zip Only' },
      { value: '10', label: '10 miles' },
      { value: '25', label: '25 miles' },
      { value: '50', label: '50 miles' },
      { value: '75', label: '75 miles' },
      { value: '100', label: '100 miles' },
      { value: '200', label: '200 miles' },
      { value: '300', label: '300 miles' },
      { value: '500', label: '500 miles' },
      { value: '1000', label: '1000 miles' }
    ],
    zip: '',
    state: '',
    radius: '',
    inputZip: '',
    switcherIdx: this.props.switcherIdx || null
  }

  handleTracking(type, value) {
    if (this.props.tracking) {
      if (value) {
        this.props.tracking.facetAdded({[type]: value});
      } else {
        this.props.tracking.facetRemoved(`removed ${type}`);
      }
    }
  }

  onMultidataChange(type, value, debounce) {
    const city = DEFAULT_REFINE_SEARCH.city;
    const zip = DEFAULT_REFINE_SEARCH.zip;
    const state = DEFAULT_REFINE_SEARCH.state;
    let locationChanged = {city, zip, state};
    if (type === 'state') {
      locationChanged.state = value;
      locationChanged.radius = this.props.searchPage === ENGINES_ID ? DEFAULT_ENGINE_REFINE_SEARCH.radius : DEFAULT_REFINE_SEARCH.radius;
    } else if (type === 'radius') {
      locationChanged.state = this.props.state || state;
      locationChanged.city = this.props.selectedCities?.length === 1 ? this.props.selectedCities : city;
      locationChanged.zip = this.props.zip || zip;
      locationChanged.radius = value;
    } else {
      locationChanged = {...locationChanged, [type]: value};
    }

    this.props.handleMultiDataChange(locationChanged, debounce);
  }

  handleDataChange(type, value, debounce) {
    this.handleTracking(type, value);
    this.onMultidataChange(type, value, debounce);
  }

  updateMultiInput() {
    if (this.props.zip !== undefined) {
      this.setState({ zip: this.props.zip });
    }
    if (this.props.radius) {
      this.setState({ radius: this.props.radius });
    }
    if (this.props.state !== undefined) {
      this.setState({ state: this.props.state });
    }
  }

  componentDidMount() {
    this.updateMultiInput();
    this.setState({ inputZip: this.props.zip });
  }

  componentDidUpdate() {
    this.updateMultiInput();
  }

  onChangeDistance = (value) => {
    this.handleDataChange('radius', value.target.value);
  }

  onChangeZipCode = (value) => {
    this.setState({ inputZip: value.target.value });
    if (value.target.value.length === 5 || value.target.value.length === 0) {
      this.handleDataChange('zip', value.target.value, true);
    }
  }

  onClickState = (value) => {
    this.handleDataChange('state', value);
  }

  onUserLocationClick = (userZipCode, userRadiusMiles) => {

    const city = DEFAULT_REFINE_SEARCH.city;
    const state = DEFAULT_REFINE_SEARCH.state;
    let locationChanged = {city, state};

    this.setState({ inputZip: userZipCode });
    this.handleTracking('zip', userZipCode);
    this.handleTracking('radius', userRadiusMiles);

    locationChanged.state = state;
    locationChanged.city = city;
    locationChanged.zip = userZipCode;
    locationChanged.radius = userRadiusMiles;

    this.props.handleMultiDataChange(locationChanged, true);
  }

  onSwitcherChange = (_, value) => {
    this.setState({ switcherIdx: value });
  }

  render() {
    let { state = '', country, states, position, params = {}, searchPage = '', hideUserLocation } = this.props;
    let { radius, locationOptions, switcherIdx } = this.state;
    let activeIndex = switcherIdx || params.state && !params.zip ? 1 : 0;

    return (
      <div className="location-filter-wrapper">
        <Switcher
          defaultIndex={activeIndex}
          fullWidth
          options={switcherOptions}
          onSwitch={this.onSwitcherChange}
        />

        {activeIndex === 0 && (
          <div className={classnames('search-filter location', {loading: this.props.loading})}>
            <div className="search-filter-group">
              <select
                className="filter-select"
                data-e2e="distance-radius"
                value={radius}
                onChange={this.onChangeDistance}
                title="Distance radius"
              >
                {locationOptions.map((obj) =>
                  <option key={obj.value} value={obj.value}>{obj.label}</option>
                )}
              </select>
              <span>from</span>
              <input
                type="number"
                data-e2e="zip-code-input"
                className="zip"
                value={this.state.inputZip ? this.state.inputZip : this.state.zip}
                placeholder="Zip"
                onChange={this.onChangeZipCode}
                title="Zip code"
              />
            </div>
            {!hideUserLocation && <SearchUserLocation onUserLocationClick={this.onUserLocationClick}/>}
          </div>
        )}

        {activeIndex === 1 && (
          <Fragment>
            <FilterState
              state={state}
              country={country}
              states={states}
              position={position}
              params={params}
              loading={this.props.loading}
              selectedState={this.state.state}
              onClickState={this.onClickState}
              searchPage={searchPage}
            />
            <FilterCity
              loading={this.props.loading}
              position={this.props.position}
              selectedState={this.props.selectedState}
              selectedCities={this.props.selectedCities}
              stateCity={this.props.stateCity}
              handleMultiDataChange={this.props.handleMultiDataChange}
              params={params}
              tracking={this.props.tracking}
              searchPage={searchPage}
            />
            <FilterZip
              loading={this.props.loading}
              position={this.props.position}
              selectedCities={this.props.selectedCities}
              cityZip={this.props.cityZip}
              zip={this.state.inputZip}
              handleMultiDataChange={this.props.handleMultiDataChange}
              params={params}
              tracking={this.props.tracking}
              searchPage={searchPage}
            />
          </Fragment>
        )}
      </div>
    );
  }
}

export default FilterLocation;
